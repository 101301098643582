import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import playstore from '.././assets/playstore.png';
import "./Footer.css"; // Assuming you're importing CSS styles from a file

const Footer = () => {
  return (
    <div className="footer">
     
     {/* <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: '10px' }}>
  <h4 style={{ margin: 0 }}>
    For a better experience, download the NammaVirundhu app now
  </h4>
  <Link
    to="https://play.google.com/store/apps/details?id=com.abitech.nammavirundhu"
    style={{
      display: 'inline-block',
      lineHeight: 0,          
      padding: 0,             
      margin: 0,              
    }}
  >
    <img
      src={playstore}
      alt="playstore"
      className="playstore"
    />
  </Link>
</div> */}

<div className="app-download-container">
      <p className="download-text">
      For a better experience, download the NammaVirundhu app now
      </p>
      <div className="button-container">
        <a
          href="https://play.google.com/store/apps/details?id=com.abitech.nammavirundhu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
            alt="Get it on Google Play"
            className="store-button"
          />
        </a>
      </div>
    </div>



  <div className="sb_footer section_padding">
 
      <div className="sb_footer-links">
       
      <div className="sb_footer-links-div">
        <h4>Conditions</h4>
        <a href="/privacy">
          <p>Privacy Policy</p>
        </a>
        <a href="/terms">
          <p>Terms & Conditions</p>
        </a>
        <a href="/refund">
          <p>Refund Policy</p>
        </a>
      </div>
      <div className="sb_footer-links-div">
        <h4>Quick Links</h4>
        <a href="/">
          <p>Home</p>
        </a>
        <a href="/about">
          <p>About</p>
        </a>
        <a href="/contact">
          <p>Contact Us</p>
        </a>
      </div>
      <div className="sb_footer-links-div">
        <h4>Social Media</h4>
        <a href="https://www.facebook.com" className="FooterLink">
              <FontAwesomeIcon icon={faFacebookF} className="socialicon" />
              <span>Facebook</span>
            </a>
            <a href="https://www.instagram.com" className="FooterLink">
              <FontAwesomeIcon icon={faInstagram} className="socialicon" />
              <span>Instagram</span>
            </a>
      </div>
      <div className="sb_footer-links-div">
        <h4>Contact Us</h4>
        <a href="mailto:demo@gmail.com" className="FooterLink">
              <FontAwesomeIcon icon={faEnvelope} className="contacticon" />
              <span>nammavirundhu@gmail.com</span>
            </a>
            <a href="tel:+917867890564" className="FooterLink">
              <FontAwesomeIcon icon={faPhone} className="contacticon" />
              <span>+91 9003750387</span>
            </a>
      </div>
      </div>
    </div>
  
  <div className="CopyrightText">
        <p>
        © {new Date().getFullYear()} NammaVirundhu. All Rights Reserved.
        </p>
      </div>
</div>
    
  );
};

export default Footer;



